
type Languages = 'en-US';
type Sections = 'Routes' | 'Footer' | 'Header' | 'MegaMenu' | 'AllCategoriesExpandedMenu' | 'MiniCart' | 'Language' | 'productDetail' | 'AddressBook' | 'Cart' | 'CheckoutAddress' | 'Checkout' | 'Payment' | 'MultipleShipmentTable' | 'Shipping' | 'OrderConfirmation' | 'SignInPage' | 'Account' | 'AccountSummary' | 'ChangePasswordSection' | 'PersonalInformationSection' | 'WelcomeUserSection' | 'AdminTools' | 'BuyerOrganizationRegistration' | 'BuyerUserRegistration' | 'Dashboard' | 'Order' | 'Category' | 'AccountLinksGridView' | 'AccountLinks' | 'MyAccount' | 'AddressCard' | 'AddressForm' | 'ChangePassword' | 'CheckoutProfile' | 'ChildPimCategories' | 'Confirmation' | 'FeaturedCard' | 'ForgotPassword' | 'PriceDisplay' | 'error-message' | 'success-message' | 'OrderBillingInfo' | 'OrderDetails' | 'OrderDiscountSummary' | 'OrderItemTable' | 'OrderLinks' | 'OrderPaymentInfo' | 'OrderShippingInfo' | 'OrderTotalSummary' | 'PaymentMethodContainer' | 'PaymentInfoCard' | 'PaymentInfoList' | 'PaymentMethodSelection' | 'PersonalInformation' | 'ProductFilter' | 'ProductGrid' | 'PurchaseOrderNumber' | 'PurchaseOrderSelection' | 'RecurringOrderInfo' | 'RegistrationLayout' | 'RegistrationB2BLayout' | 'ResetPassword' | 'SearchBar' | 'SessionError' | 'SignIn' | 'Pickup' | 'CommerceEnvironment' | 'RequisitionLists' | 'RequisitionListItems' | 'InprogressOrders' | 'InprogressItems' | 'commonTable' | 'WishList' | 'OrderMethod' | 'compare' | 'sellers' | 'StoreLocator' | 'ProgressIndicator' | 'Files' | 'PasswordInput' | 'Common' | 'Close' | 'ErrorMessages' | 'ProductNotFound';
export type Translation = Record<string, string | MixedObj>;
interface MixedObj extends Translation {}
type ArgTypes = string | number;
type TemplateArgs = Record<string, ArgTypes>;

const manifest: Record<
    Languages | string,
    Record<Sections | string, () => Promise<Translation>>
> = {
    'en-US': {
        'Routes': () => import('./en-US/Routes'),
        'Footer': () => import('./en-US/Footer'),
        'Header': () => import('./en-US/Header'),
        'MegaMenu': () => import('./en-US/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./en-US/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./en-US/MiniCart'),
        'Language': () => import('./en-US/Language'),
        'productDetail': () => import('./en-US/productDetail'),
        'AddressBook': () => import('./en-US/AddressBook'),
        'Cart': () => import('./en-US/Cart'),
        'CheckoutAddress': () => import('./en-US/CheckoutAddress'),
        'Checkout': () => import('./en-US/Checkout'),
        'Payment': () => import('./en-US/Payment'),
        'MultipleShipmentTable': () => import('./en-US/MultipleShipmentTable'),
        'Shipping': () => import('./en-US/Shipping'),
        'OrderConfirmation': () => import('./en-US/OrderConfirmation'),
        'SignInPage': () => import('./en-US/SignInPage'),
        'Account': () => import('./en-US/Account'),
        'AccountSummary': () => import('./en-US/AccountSummary'),
        'ChangePasswordSection': () => import('./en-US/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./en-US/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./en-US/WelcomeUserSection'),
        'AdminTools': () => import('./en-US/AdminTools'),
        'BuyerOrganizationRegistration': () => import('./en-US/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./en-US/BuyerUserRegistration'),
        'Dashboard': () => import('./en-US/Dashboard'),
        'Order': () => import('./en-US/Order'),
        'Category': () => import('./en-US/Category'),
        'AccountLinksGridView': () => import('./en-US/AccountLinksGridView'),
        'AccountLinks': () => import('./en-US/AccountLinks'),
        'MyAccount': () => import('./en-US/MyAccount'),
        'AddressCard': () => import('./en-US/AddressCard'),
        'AddressForm': () => import('./en-US/AddressForm'),
        'ChangePassword': () => import('./en-US/ChangePassword'),
        'CheckoutProfile': () => import('./en-US/CheckoutProfile'),
        'ChildPimCategories': () => import('./en-US/ChildPimCategories'),
        'Confirmation': () => import('./en-US/Confirmation'),
        'FeaturedCard': () => import('./en-US/FeaturedCard'),
        'ForgotPassword': () => import('./en-US/ForgotPassword'),
        'PriceDisplay': () => import('./en-US/PriceDisplay'),
        'error-message': () => import('./en-US/error-message'),
        'success-message': () => import('./en-US/success-message'),
        'OrderBillingInfo': () => import('./en-US/OrderBillingInfo'),
        'OrderDetails': () => import('./en-US/OrderDetails'),
        'OrderDiscountSummary': () => import('./en-US/OrderDiscountSummary'),
        'OrderItemTable': () => import('./en-US/OrderItemTable'),
        'OrderLinks': () => import('./en-US/OrderLinks'),
        'OrderPaymentInfo': () => import('./en-US/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./en-US/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./en-US/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./en-US/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./en-US/PaymentInfoCard'),
        'PaymentInfoList': () => import('./en-US/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./en-US/PaymentMethodSelection'),
        'PersonalInformation': () => import('./en-US/PersonalInformation'),
        'ProductFilter': () => import('./en-US/ProductFilter'),
        'ProductGrid': () => import('./en-US/ProductGrid'),
        'PurchaseOrderNumber': () => import('./en-US/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./en-US/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./en-US/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./en-US/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./en-US/RegistrationB2BLayout'),
        'ResetPassword': () => import('./en-US/ResetPassword'),
        'SearchBar': () => import('./en-US/SearchBar'),
        'SessionError': () => import('./en-US/SessionError'),
        'SignIn': () => import('./en-US/SignIn'),
        'Pickup': () => import('./en-US/Pickup'),
        'CommerceEnvironment': () => import('./en-US/CommerceEnvironment'),
        'RequisitionLists': () => import('./en-US/RequisitionLists'),
        'RequisitionListItems': () => import('./en-US/RequisitionListItems'),
        'InprogressOrders': () => import('./en-US/InprogressOrders'),
        'InprogressItems': () => import('./en-US/InprogressItems'),
        'commonTable': () => import('./en-US/commonTable'),
        'WishList': () => import('./en-US/WishList'),
        'OrderMethod': () => import('./en-US/OrderMethod'),
        'compare': () => import('./en-US/compare'),
        'sellers': () => import('./en-US/sellers'),
        'StoreLocator': () => import('./en-US/StoreLocator'),
        'ProgressIndicator': () => import('./en-US/ProgressIndicator'),
        'Files': () => import('./en-US/Files'),
        'PasswordInput': () => import('./en-US/PasswordInput'),
        'Common': () => import('./en-US/Common'),
        'Close': () => import('./en-US/Close'),
        'ErrorMessages': () => import('./en-US/ErrorMessages'),
        'ProductNotFound': () => import('./en-US/ProductNotFound'),
    },
};

export interface TranslationTable {'Routes': {'Search': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Login': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Account': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'ForgotPassword': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'ResetPassword': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Registration': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Cart': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'CheckOut': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'AddressBook': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'WishLists': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'CheckoutProfiles': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrderConfirmation': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrderHistory': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrderDetails': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'SessionError': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Error404': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Error500': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'StoreLocator': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'CompareProducts': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerUserRegistration': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerOrganizationRegistration': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'RequisitionLists': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'RequisitionListDetails': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'RequisitionListsUploadLogs': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'ProductNotFound': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'Footer': {'CompanyLinks': {'Label': {t: (args?: [...ArgTypes[]]) => string;};
'OurStory': {t: (args?: [...ArgTypes[]]) => string;};
'Careers': {t: (args?: [...ArgTypes[]]) => string;};
};
'CopyrightFull': {t: ({date}: TemplateArgs) => string;};
'Copyright': {t: (args?: [...ArgTypes[]]) => string;};
'CustomerService': {'Label': {t: (args?: [...ArgTypes[]]) => string;};
'ContactUs': {t: (args?: [...ArgTypes[]]) => string;};
'ContactUsDescription': {t: (args?: [...ArgTypes[]]) => string;};
'PrivacyPolicy': {t: (args?: [...ArgTypes[]]) => string;};
};
'FollowUs': {t: (args?: [...ArgTypes[]]) => string;};
'Description': {t: (args?: [...ArgTypes[]]) => string;};
'DisabledMessage': {t: (args?: [...ArgTypes[]]) => string;};
};
'Header': {'Actions': {'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'SignInRegister': {t: (args?: [...ArgTypes[]]) => string;};
'SignRegSeller': {t: (args?: [...ArgTypes[]]) => string;};
'WelcomeFirstName': {t: ({firstName}: TemplateArgs) => string;};
'WelcomeNoFirstName': {t: (args?: [...ArgTypes[]]) => string;};
'WishList': {t: (args?: [...ArgTypes[]]) => string;};
'YourAccount': {t: (args?: [...ArgTypes[]]) => string;};
'Account': {t: (args?: [...ArgTypes[]]) => string;};
};
'AccountPopper': {'Welcome': {t: ({firstName}: TemplateArgs) => string;};
'Organization': {t: (args?: [...ArgTypes[]]) => string;};
'Contract': {t: (args?: [...ArgTypes[]]) => string;};
'Edit': {t: (args?: [...ArgTypes[]]) => string;};
'AccountSetting': {t: (args?: [...ArgTypes[]]) => string;};
'SignOut': {t: (args?: [...ArgTypes[]]) => string;};
'Cart': {t: ({count}: TemplateArgs) => string;};
'Done': {t: (args?: [...ArgTypes[]]) => string;};
'MyAccount': {t: (args?: [...ArgTypes[]]) => string;};
'ForgotPassword': {t: (args?: [...ArgTypes[]]) => string;};
'Register': {t: (args?: [...ArgTypes[]]) => string;};
'DontHaveAnAccount': {t: (args?: [...ArgTypes[]]) => string;};
'EmailAddress': {t: (args?: [...ArgTypes[]]) => string;};
'Login': {t: (args?: [...ArgTypes[]]) => string;};
'AccountMenu': {t: (args?: [...ArgTypes[]]) => string;};
'CSRHomePage': {t: (args?: [...ArgTypes[]]) => string;};
'QuickOrder': {t: (args?: [...ArgTypes[]]) => string;};
'CSRGuest': {t: (args?: [...ArgTypes[]]) => string;};
'CSRRegistered': {t: ({userName, logonId}: TemplateArgs) => string;};
'CSRGuestRegister': {t: (args?: [...ArgTypes[]]) => string;};
'CSRSigninSelf': {t: (args?: [...ArgTypes[]]) => string;};
'CSRDialogContent': {t: ({name}: TemplateArgs) => string;};
'Yes': {t: (args?: [...ArgTypes[]]) => string;};
'No': {t: (args?: [...ArgTypes[]]) => string;};
};
'StoreSwitch': {'ChangeCountry': {t: (args?: [...ArgTypes[]]) => string;};
'PleaseSelectACountry': {t: (args?: [...ArgTypes[]]) => string;};
'WacoalAmerica': {t: (args?: [...ArgTypes[]]) => string;};
'WacoalCanada': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'MegaMenu': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {'ViewAll': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'AllCategoriesExpandedMenu': {'AllCategoriesLabel': {t: (args?: [...ArgTypes[]]) => string;};
};
'MiniCart': {'Items': {t: ({count}: TemplateArgs) => string;};
'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Subtotal': {t: ({count}: TemplateArgs) => string;};
'Empty': {t: (args?: [...ArgTypes[]]) => string;};
'AndNMore': {t: ({count}: TemplateArgs) => string;};
'Actions': {'Cart': {t: (args?: [...ArgTypes[]]) => string;};
'CheckOut': {t: (args?: [...ArgTypes[]]) => string;};
'ViewOrderDetails': {t: (args?: [...ArgTypes[]]) => string;};
};
'MyOrder': {t: (args?: [...ArgTypes[]]) => string;};
'PrivateOrder': {t: (args?: [...ArgTypes[]]) => string;};
'SharedOrderConributor': {t: (args?: [...ArgTypes[]]) => string;};
'SharedOrderAdmin': {t: (args?: [...ArgTypes[]]) => string;};
'ProceedToCheckout': {t: (args?: [...ArgTypes[]]) => string;};
'Additional_Savings': {t: (args?: [...ArgTypes[]]) => string;};
};
'Language': {'-1': {t: (args?: [...ArgTypes[]]) => string;};
'-2': {t: (args?: [...ArgTypes[]]) => string;};
'-3': {t: (args?: [...ArgTypes[]]) => string;};
'-4': {t: (args?: [...ArgTypes[]]) => string;};
'-5': {t: (args?: [...ArgTypes[]]) => string;};
'-6': {t: (args?: [...ArgTypes[]]) => string;};
'-7': {t: (args?: [...ArgTypes[]]) => string;};
'-8': {t: (args?: [...ArgTypes[]]) => string;};
'-10': {t: (args?: [...ArgTypes[]]) => string;};
'-20': {t: (args?: [...ArgTypes[]]) => string;};
'-21': {t: (args?: [...ArgTypes[]]) => string;};
'-9': {t: (args?: [...ArgTypes[]]) => string;};
'-22': {t: (args?: [...ArgTypes[]]) => string;};
'-23': {t: (args?: [...ArgTypes[]]) => string;};
};
'productDetail': {'Quantity': {t: (args?: [...ArgTypes[]]) => string;};
'QtyError': {t: (args?: [...ArgTypes[]]) => string;};
'Availability': {t: (args?: [...ArgTypes[]]) => string;};
'AddToCart': {t: (args?: [...ArgTypes[]]) => string;};
'Seller': {t: ({seller}: TemplateArgs) => string;};
'SellerSimple': {t: ({seller}: TemplateArgs) => string;};
'AddBundleToCart': {t: (args?: [...ArgTypes[]]) => string;};
'AddKitToCart': {t: (args?: [...ArgTypes[]]) => string;};
'AddKitToSharedOrder': {t: (args?: [...ArgTypes[]]) => string;};
'AddBundleToSharedOrder': {t: (args?: [...ArgTypes[]]) => string;};
'SelectAttributes': {t: (args?: [...ArgTypes[]]) => string;};
'Description': {t: (args?: [...ArgTypes[]]) => string;};
'ProductDetails': {t: (args?: [...ArgTypes[]]) => string;};
'Attachments': {t: (args?: [...ArgTypes[]]) => string;};
'AllAttachments': {t: (args?: [...ArgTypes[]]) => string;};
'ViewAll': {t: (args?: [...ArgTypes[]]) => string;};
'Images': {t: (args?: [...ArgTypes[]]) => string;};
'Videos': {t: (args?: [...ArgTypes[]]) => string;};
'Documents': {t: (args?: [...ArgTypes[]]) => string;};
'PricePending': {t: (args?: [...ArgTypes[]]) => string;};
'addToCartErrorMsg': {t: (args?: [...ArgTypes[]]) => string;};
'any': {t: (args?: [...ArgTypes[]]) => string;};
'showAttributes': {t: (args?: [...ArgTypes[]]) => string;};
'attributeFilter': {t: (args?: [...ArgTypes[]]) => string;};
'addToCurrentOrder': {t: (args?: [...ArgTypes[]]) => string;};
'addToSharedOrder': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'noproductsToDisplay': {t: (args?: [...ArgTypes[]]) => string;};
'SKU': {t: (args?: [...ArgTypes[]]) => string;};
'skuLabel': {t: ({value}: TemplateArgs) => string;};
'descAttrLabel': {t: ({name, values}: TemplateArgs) => string;};
'PRODUCTSKU': {t: (args?: [...ArgTypes[]]) => string;};
'PRODUCT': {t: (args?: [...ArgTypes[]]) => string;};
'PRODUCTNAME': {t: (args?: [...ArgTypes[]]) => string;};
'QUANTITY': {t: (args?: [...ArgTypes[]]) => string;};
'SelectAnOption': {t: (args?: [...ArgTypes[]]) => string;};
'Unconfigured': {t: (args?: [...ArgTypes[]]) => string;};
'Unavailable': {t: (args?: [...ArgTypes[]]) => string;};
'InStock': {t: (args?: [...ArgTypes[]]) => string;};
'Price': {t: (args?: [...ArgTypes[]]) => string;};
'Online_Availability': {t: (args?: [...ArgTypes[]]) => string;};
'recommendedProdTitle': {t: (args?: [...ArgTypes[]]) => string;};
'someWithNoSkus': {t: (args?: [...ArgTypes[]]) => string;};
'someWithNoAvlSelZero': {t: (args?: [...ArgTypes[]]) => string;};
'someWithNoAvlPlsRemove': {t: (args?: [...ArgTypes[]]) => string;};
'someWithNotEnough': {t: (args?: [...ArgTypes[]]) => string;};
'selectSomething': {t: (args?: [...ArgTypes[]]) => string;};
'noAttributes': {t: (args?: [...ArgTypes[]]) => string;};
'noproductsKit': {t: (args?: [...ArgTypes[]]) => string;};
'addToRL': {t: (args?: [...ArgTypes[]]) => string;};
'createRL': {t: (args?: [...ArgTypes[]]) => string;};
'addToWL': {t: (args?: [...ArgTypes[]]) => string;};
'createWL': {t: (args?: [...ArgTypes[]]) => string;};
'QuantityValue': {t: ({quantity}: TemplateArgs) => string;};
'hideAttrs': {t: (args?: [...ArgTypes[]]) => string;};
'removeFromSelection': {t: (args?: [...ArgTypes[]]) => string;};
'chooseQuantity': {t: (args?: [...ArgTypes[]]) => string;};
'noAttrs': {t: (args?: [...ArgTypes[]]) => string;};
'detailsNotAvailable': {t: (args?: [...ArgTypes[]]) => string;};
'Style': {t: ({style}: TemplateArgs) => string;};
'CustomersAlsoLoved': {t: (args?: [...ArgTypes[]]) => string;};
'Details': {t: (args?: [...ArgTypes[]]) => string;};
'CompleteYourLook': {t: (args?: [...ArgTypes[]]) => string;};
'FitTips': {t: (args?: [...ArgTypes[]]) => string;};
'CurrentlyUnavailable': {t: (args?: [...ArgTypes[]]) => string;};
'LowStock': {t: ({quantity}: TemplateArgs) => string;};
'InStockDate': {t: ({date}: TemplateArgs) => string;};
'EmailMe': {t: (args?: [...ArgTypes[]]) => string;};
'EmailAddress': {t: (args?: [...ArgTypes[]]) => string;};
'Submit': {t: (args?: [...ArgTypes[]]) => string;};
'GuestUserWishlistTitle': {t: (args?: [...ArgTypes[]]) => string;};
'WishlistSignIn': {t: (args?: [...ArgTypes[]]) => string;};
};
'AddressBook': {'AddrMsg': {t: (args?: [...ArgTypes[]]) => string;};
'AddButton': {t: (args?: [...ArgTypes[]]) => string;};
'Title': {t: (args?: [...ArgTypes[]]) => string;};
'FilterLabel': {t: (args?: [...ArgTypes[]]) => string;};
'NoAddrMsg': {t: (args?: [...ArgTypes[]]) => string;};
'NoAddrForFilterMsg': {t: (args?: [...ArgTypes[]]) => string;};
'ShowAllLabel': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingLabel': {t: (args?: [...ArgTypes[]]) => string;};
'BillingLabel': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingBillingLabel': {t: (args?: [...ArgTypes[]]) => string;};
'CreateAddress': {t: (args?: [...ArgTypes[]]) => string;};
'SaveChanges': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'EditAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'Cart': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'Empty': {t: (args?: [...ArgTypes[]]) => string;};
'PromoCode': {t: (args?: [...ArgTypes[]]) => string;};
'ShopNow': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'PromoCode': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrder': {t: (args?: [...ArgTypes[]]) => string;};
'Frequency': {t: (args?: [...ArgTypes[]]) => string;};
'StartDate': {t: (args?: [...ArgTypes[]]) => string;};
'OrderSummary': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'Checkout': {t: (args?: [...ArgTypes[]]) => string;};
'ContinueShopping': {t: (args?: [...ArgTypes[]]) => string;};
'Apply': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutWithProfile': {t: (args?: [...ArgTypes[]]) => string;};
};
'Availability': {'NOT_AVAIL_PICKUP': {t: ({storeName}: TemplateArgs) => string;};
'AVAIL_PICKUP': {t: ({storeName}: TemplateArgs) => string;};
'NOT_AVAIL_DELIVERY': {t: (args?: [...ArgTypes[]]) => string;};
'AVAIL_DELIVERY': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'CheckoutAddress': {'Actions': {'Submit': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
};
'Payment': {'ChooseFirst': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'Checkout': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutProfileTitle': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'shipping': {t: (args?: [...ArgTypes[]]) => string;};
'payment': {t: (args?: [...ArgTypes[]]) => string;};
'review': {t: (args?: [...ArgTypes[]]) => string;};
'pickup-store': {t: (args?: [...ArgTypes[]]) => string;};
'pickup': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'ReturnCart': {t: (args?: [...ArgTypes[]]) => string;};
'BackTo': {t: ({step}: TemplateArgs) => string;};
'ContinueTo': {t: ({step}: TemplateArgs) => string;};
'PlaceOrder': {t: (args?: [...ArgTypes[]]) => string;};
};
'PickupNotAvailable': {t: (args?: [...ArgTypes[]]) => string;};
};
'Payment': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'TitleMulti': {t: ({total}: TemplateArgs) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
'SelectShippingAddress': {t: (args?: [...ArgTypes[]]) => string;};
'SelectBillingAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'UseMultiple': {t: (args?: [...ArgTypes[]]) => string;};
'PaymentMethod': {t: ({number}: TemplateArgs) => string;};
'AddNewAddress': {t: (args?: [...ArgTypes[]]) => string;};
'BillingAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'Back': {t: (args?: [...ArgTypes[]]) => string;};
'BackToPickup': {t: (args?: [...ArgTypes[]]) => string;};
'Next': {t: (args?: [...ArgTypes[]]) => string;};
'Finish': {t: (args?: [...ArgTypes[]]) => string;};
'SaveAndAdd': {t: (args?: [...ArgTypes[]]) => string;};
'AddAnotherPayMethod': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'MultipleShipmentTable': {'Labels': {'ItemDetails': {t: (args?: [...ArgTypes[]]) => string;};
'SKU': {t: (args?: [...ArgTypes[]]) => string;};
'Quantity': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingDetails': {t: (args?: [...ArgTypes[]]) => string;};
'SelectShippingAddressAndMethod': {t: (args?: [...ArgTypes[]]) => string;};
'ChangeSelection': {t: (args?: [...ArgTypes[]]) => string;};
'nItemsSel': {t: ({n}: TemplateArgs) => string;};
'noItems': {t: (args?: [...ArgTypes[]]) => string;};
'SelectAll': {t: (args?: [...ArgTypes[]]) => string;};
'OrderItems': {t: ({n}: TemplateArgs) => string;};
'Select': {t: (args?: [...ArgTypes[]]) => string;};
'OutOfStock': {t: (args?: [...ArgTypes[]]) => string;};
'SelInvalid': {t: (args?: [...ArgTypes[]]) => string;};
};
'Msgs': {'MissingSelection': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'Shipping': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'UseMultiple': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingAddress': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingMethod': {t: (args?: [...ArgTypes[]]) => string;};
'SelectShippingMethod': {t: (args?: [...ArgTypes[]]) => string;};
'AddNewAddress': {t: (args?: [...ArgTypes[]]) => string;};
'EditAddress': {t: (args?: [...ArgTypes[]]) => string;};
'SelectedCount': {t: ({selected, all}: TemplateArgs) => string;};
'ItemsSelected': {t: ({itemsSelected}: TemplateArgs) => string;};
};
'Actions': {'SelectAddress': {t: (args?: [...ArgTypes[]]) => string;};
'CreateNew': {t: (args?: [...ArgTypes[]]) => string;};
'Next': {t: (args?: [...ArgTypes[]]) => string;};
'Confirm': {t: (args?: [...ArgTypes[]]) => string;};
'Back': {t: (args?: [...ArgTypes[]]) => string;};
'Done': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'SelectShippingAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'Msgs': {'SelectExisting': {t: (args?: [...ArgTypes[]]) => string;};
'UseSavedAddress': {t: (args?: [...ArgTypes[]]) => string;};
'SelectShippingAddress': {t: (args?: [...ArgTypes[]]) => string;};
'Incomplete': {t: (args?: [...ArgTypes[]]) => string;};
'SelectOrCreateAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderConfirmation': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'Heading': {t: (args?: [...ArgTypes[]]) => string;};
'Pending': {t: (args?: [...ArgTypes[]]) => string;};
'OrderNumber': {t: ({orderId}: TemplateArgs) => string;};
'Details': {t: ({emails}: TemplateArgs) => string;};
'PendingDetails': {t: (args?: [...ArgTypes[]]) => string;};
'ThankYou': {t: ({storeName}: TemplateArgs) => string;};
};
};
'SignInPage': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
};
'Account': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
};
'AccountSummary': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
};
'ChangePasswordSection': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'CurrentPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'NewPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'VerifyPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SaveLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SuccessLabel': {t: (args?: [...ArgTypes[]]) => string;};
'OkLabel': {t: (args?: [...ArgTypes[]]) => string;};
};
'PersonalInformationSection': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Name': {t: (args?: [...ArgTypes[]]) => string;};
'EmailAddress': {t: (args?: [...ArgTypes[]]) => string;};
'PhoneNumber': {t: (args?: [...ArgTypes[]]) => string;};
'Address': {t: (args?: [...ArgTypes[]]) => string;};
'SaveChanges': {t: (args?: [...ArgTypes[]]) => string;};
'UpdateSuccessful': {t: (args?: [...ArgTypes[]]) => string;};
};
'WelcomeUserSection': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Message': {t: (args?: [...ArgTypes[]]) => string;};
};
'AdminTools': {'buyerManagement': {t: (args?: [...ArgTypes[]]) => string;};
'orgAndBuyer': {t: (args?: [...ArgTypes[]]) => string;};
'orgManagement': {t: (args?: [...ArgTypes[]]) => string;};
'buyerManagementDesc': {t: (args?: [...ArgTypes[]]) => string;};
'orgAndBuyerDesc': {t: (args?: [...ArgTypes[]]) => string;};
'orgManagementDesc': {t: (args?: [...ArgTypes[]]) => string;};
'adminTools': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerOrganizationRegistration': {'OrganizationRegistration': {t: (args?: [...ArgTypes[]]) => string;};
'BuyerAdminRegistration': {t: (args?: [...ArgTypes[]]) => string;};
'Title': {t: (args?: [...ArgTypes[]]) => string;};
'AddressDetails': {t: (args?: [...ArgTypes[]]) => string;};
'AddressLineAdd': {t: (args?: [...ArgTypes[]]) => string;};
'Back': {t: (args?: [...ArgTypes[]]) => string;};
'NextRegister': {t: (args?: [...ArgTypes[]]) => string;};
'CompleteRegistration': {t: (args?: [...ArgTypes[]]) => string;};
'HaveAnAccount': {t: (args?: [...ArgTypes[]]) => string;};
'HereAnAccount': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'OrganizationName': {t: (args?: [...ArgTypes[]]) => string;};
'AccountPreferences': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'Address1': {t: (args?: [...ArgTypes[]]) => string;};
'Address2': {t: (args?: [...ArgTypes[]]) => string;};
'City': {t: (args?: [...ArgTypes[]]) => string;};
'Country': {t: (args?: [...ArgTypes[]]) => string;};
'State': {t: (args?: [...ArgTypes[]]) => string;};
'ZipCode': {t: (args?: [...ArgTypes[]]) => string;};
'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'LogonId': {t: (args?: [...ArgTypes[]]) => string;};
'Phone': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'Password2': {t: (args?: [...ArgTypes[]]) => string;};
'Language': {t: (args?: [...ArgTypes[]]) => string;};
'Currency': {t: (args?: [...ArgTypes[]]) => string;};
'UseOrganizationAddress': {t: (args?: [...ArgTypes[]]) => string;};
'Submit': {t: (args?: [...ArgTypes[]]) => string;};
'Clear': {t: (args?: [...ArgTypes[]]) => string;};
'Success': {t: (args?: [...ArgTypes[]]) => string;};
'OK': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
'ZipCodePostalCode': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerUserRegistration': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Description': {t: (args?: [...ArgTypes[]]) => string;};
'OrgName': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'Address1': {t: (args?: [...ArgTypes[]]) => string;};
'Address2': {t: (args?: [...ArgTypes[]]) => string;};
'City': {t: (args?: [...ArgTypes[]]) => string;};
'Country': {t: (args?: [...ArgTypes[]]) => string;};
'State': {t: (args?: [...ArgTypes[]]) => string;};
'ZipCode': {t: (args?: [...ArgTypes[]]) => string;};
'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'LogonId': {t: (args?: [...ArgTypes[]]) => string;};
'Phone': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'Password2': {t: (args?: [...ArgTypes[]]) => string;};
'Language': {t: (args?: [...ArgTypes[]]) => string;};
'Currency': {t: (args?: [...ArgTypes[]]) => string;};
'AddressDetails': {t: (args?: [...ArgTypes[]]) => string;};
'AccountPreferences': {t: (args?: [...ArgTypes[]]) => string;};
'HaveAccount': {t: (args?: [...ArgTypes[]]) => string;};
'AddressLineAdd': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'Submit': {t: (args?: [...ArgTypes[]]) => string;};
'Clear': {t: (args?: [...ArgTypes[]]) => string;};
'Success': {t: (args?: [...ArgTypes[]]) => string;};
'OK': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'Dashboard': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'AccountSettings': {t: (args?: [...ArgTypes[]]) => string;};
'PersonalInformation': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBook': {t: (args?: [...ArgTypes[]]) => string;};
'OrderManagement': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistory': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrders': {t: (args?: [...ArgTypes[]]) => string;};
'RequisitionLists': {t: (args?: [...ArgTypes[]]) => string;};
'InprogressOrders': {t: (args?: [...ArgTypes[]]) => string;};
'ApproveOrders': {t: (args?: [...ArgTypes[]]) => string;};
};
'Order': {'noPO': {t: (args?: [...ArgTypes[]]) => string;};
'Status_A': {t: (args?: [...ArgTypes[]]) => string;};
'Status_B': {t: (args?: [...ArgTypes[]]) => string;};
'Status_C': {t: (args?: [...ArgTypes[]]) => string;};
'Status_D': {t: (args?: [...ArgTypes[]]) => string;};
'Status_E': {t: (args?: [...ArgTypes[]]) => string;};
'Status_F': {t: (args?: [...ArgTypes[]]) => string;};
'Status_G': {t: (args?: [...ArgTypes[]]) => string;};
'Status_I': {t: (args?: [...ArgTypes[]]) => string;};
'Status_K': {t: (args?: [...ArgTypes[]]) => string;};
'Status_L': {t: (args?: [...ArgTypes[]]) => string;};
'Status_M': {t: (args?: [...ArgTypes[]]) => string;};
'Status_N': {t: (args?: [...ArgTypes[]]) => string;};
'Status_P': {t: (args?: [...ArgTypes[]]) => string;};
'Status_R': {t: (args?: [...ArgTypes[]]) => string;};
'Status_S': {t: (args?: [...ArgTypes[]]) => string;};
'Status_T': {t: (args?: [...ArgTypes[]]) => string;};
'Status_W': {t: (args?: [...ArgTypes[]]) => string;};
'Status_V': {t: (args?: [...ArgTypes[]]) => string;};
'Status_X': {t: (args?: [...ArgTypes[]]) => string;};
'Status_RTN': {t: (args?: [...ArgTypes[]]) => string;};
'Status_APP': {t: (args?: [...ArgTypes[]]) => string;};
'NotAvailable': {t: (args?: [...ArgTypes[]]) => string;};
'OrderId': {t: (args?: [...ArgTypes[]]) => string;};
'PONumber': {t: (args?: [...ArgTypes[]]) => string;};
'OrderDate': {t: (args?: [...ArgTypes[]]) => string;};
'Schedule': {t: (args?: [...ArgTypes[]]) => string;};
'NextOrder': {t: (args?: [...ArgTypes[]]) => string;};
'Status': {t: (args?: [...ArgTypes[]]) => string;};
'TotalPrice': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {t: (args?: [...ArgTypes[]]) => string;};
'RowCount': {t: (args?: [...ArgTypes[]]) => string;};
'NoRecord': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidOrderId': {t: (args?: [...ArgTypes[]]) => string;};
'Search': {t: (args?: [...ArgTypes[]]) => string;};
'PageSizeLabel': {t: (args?: [...ArgTypes[]]) => string;};
'FirstPage': {t: (args?: [...ArgTypes[]]) => string;};
'LastPage': {t: (args?: [...ArgTypes[]]) => string;};
'PreviousPage': {t: (args?: [...ArgTypes[]]) => string;};
'NextPage': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistory': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrders': {t: (args?: [...ArgTypes[]]) => string;};
'Once': {t: (args?: [...ArgTypes[]]) => string;};
'EveryXDAY': {t: ({frequency}: TemplateArgs) => string;};
'EveryXWEE': {t: ({frequency}: TemplateArgs) => string;};
'EveryXMON': {t: ({frequency}: TemplateArgs) => string;};
'StateInactive': {t: (args?: [...ArgTypes[]]) => string;};
'StateActive': {t: (args?: [...ArgTypes[]]) => string;};
'StateExpired': {t: (args?: [...ArgTypes[]]) => string;};
'StateCancelled': {t: (args?: [...ArgTypes[]]) => string;};
'StateCompleted': {t: (args?: [...ArgTypes[]]) => string;};
'StateSuspended': {t: (args?: [...ArgTypes[]]) => string;};
'StatePendingCancel': {t: (args?: [...ArgTypes[]]) => string;};
'TooltipCancel': {t: (args?: [...ArgTypes[]]) => string;};
'TooltipReOrder': {t: (args?: [...ArgTypes[]]) => string;};
'FilterResults': {t: (args?: [...ArgTypes[]]) => string;};
'OrderDetails': {t: (args?: [...ArgTypes[]]) => string;};
'OrderNumber': {t: (args?: [...ArgTypes[]]) => string;};
'HistorySearchPlaceHolder': {t: (args?: [...ArgTypes[]]) => string;};
'HistoryViewDetailTooltip': {t: (args?: [...ArgTypes[]]) => string;};
'Pending': {t: (args?: [...ArgTypes[]]) => string;};
'multiStatus': {t: (args?: [...ArgTypes[]]) => string;};
'BackToOH': {t: (args?: [...ArgTypes[]]) => string;};
'TooltipOrderIdSearch': {t: (args?: [...ArgTypes[]]) => string;};
'purchaseOrder': {t: (args?: [...ArgTypes[]]) => string;};
};
'Category': {'notAvailable': {t: (args?: [...ArgTypes[]]) => string;};
};
'AccountLinksGridView': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBookText': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBookDescription': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistoryText': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistoryDescription': {t: (args?: [...ArgTypes[]]) => string;};
'WishListText': {t: (args?: [...ArgTypes[]]) => string;};
'WishListDescription': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutProfilesText': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutProfilesDescription': {t: (args?: [...ArgTypes[]]) => string;};
'RequisitionListsText': {t: (args?: [...ArgTypes[]]) => string;};
'RequisitionListsDescription': {t: (args?: [...ArgTypes[]]) => string;};
};
'AccountLinks': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'PersonalInformationText': {t: (args?: [...ArgTypes[]]) => string;};
'PersonalInformationDescription': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBookText': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBookDescription': {t: (args?: [...ArgTypes[]]) => string;};
'DisabledMessage': {t: (args?: [...ArgTypes[]]) => string;};
};
'MyAccount': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'AccountSettings': {t: (args?: [...ArgTypes[]]) => string;};
'PersonalInformation': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBook': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistory': {t: (args?: [...ArgTypes[]]) => string;};
'Wishlists': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutProfiles': {t: (args?: [...ArgTypes[]]) => string;};
'AccountAddress': {t: (args?: [...ArgTypes[]]) => string;};
'NoAccountAddress': {t: (args?: [...ArgTypes[]]) => string;};
'ContactInformation': {t: (args?: [...ArgTypes[]]) => string;};
'RecentOrders': {t: (args?: [...ArgTypes[]]) => string;};
'ViewOrders': {t: (args?: [...ArgTypes[]]) => string;};
};
'AddressCard': {'EditButton': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteButton': {t: (args?: [...ArgTypes[]]) => string;};
'Confirm': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'UseAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'AddressForm': {'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidAddressName': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'Address1': {t: (args?: [...ArgTypes[]]) => string;};
'Address2': {t: (args?: [...ArgTypes[]]) => string;};
'City': {t: (args?: [...ArgTypes[]]) => string;};
'Country': {t: (args?: [...ArgTypes[]]) => string;};
'State': {t: (args?: [...ArgTypes[]]) => string;};
'ZipCode': {t: (args?: [...ArgTypes[]]) => string;};
'Phone': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'NickName': {t: (args?: [...ArgTypes[]]) => string;};
'AddressType': {t: (args?: [...ArgTypes[]]) => string;};
'Shipping': {t: (args?: [...ArgTypes[]]) => string;};
'Billing': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingAndBilling': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'SaveChanges': {t: (args?: [...ArgTypes[]]) => string;};
'CreateAddress': {t: (args?: [...ArgTypes[]]) => string;};
'SaveAndSelect': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'ChangePassword': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'CurrentPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'NewPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'VerifyPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SaveLabel': {t: (args?: [...ArgTypes[]]) => string;};
'CancelLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SuccessLabel': {t: (args?: [...ArgTypes[]]) => string;};
'OkLabel': {t: (args?: [...ArgTypes[]]) => string;};
};
'CheckoutProfile': {'ShippingInformation': {t: (args?: [...ArgTypes[]]) => string;};
'CreateCheckoutTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CreateCheckoutButton': {t: (args?: [...ArgTypes[]]) => string;};
'BillingInformation': {t: (args?: [...ArgTypes[]]) => string;};
'PaymentInformation': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingMethod': {t: (args?: [...ArgTypes[]]) => string;};
'ShipSameAsBill': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'Next': {t: (args?: [...ArgTypes[]]) => string;};
'Back': {t: (args?: [...ArgTypes[]]) => string;};
'CreateProfile': {t: (args?: [...ArgTypes[]]) => string;};
'UpdateProfile': {t: (args?: [...ArgTypes[]]) => string;};
'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Label': {t: (args?: [...ArgTypes[]]) => string;};
'MyAcctDesc': {t: (args?: [...ArgTypes[]]) => string;};
'Name': {t: (args?: [...ArgTypes[]]) => string;};
'Edit': {t: (args?: [...ArgTypes[]]) => string;};
'Delete': {t: (args?: [...ArgTypes[]]) => string;};
'NoneFound': {t: (args?: [...ArgTypes[]]) => string;};
'NoProfiles': {t: (args?: [...ArgTypes[]]) => string;};
'TitleCreate': {t: (args?: [...ArgTypes[]]) => string;};
'CreateMessage': {t: (args?: [...ArgTypes[]]) => string;};
'CreateNewButton': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingAddr': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingMethodDisp': {t: (args?: [...ArgTypes[]]) => string;};
'BillingAddr': {t: (args?: [...ArgTypes[]]) => string;};
'BillingMethod': {t: (args?: [...ArgTypes[]]) => string;};
'EditProfile': {t: (args?: [...ArgTypes[]]) => string;};
'ShipAsComplete': {t: (args?: [...ArgTypes[]]) => string;};
'confirmDelete': {t: (args?: [...ArgTypes[]]) => string;};
'selectExisting': {t: (args?: [...ArgTypes[]]) => string;};
'sm_Express': {t: (args?: [...ArgTypes[]]) => string;};
'sm_Mail': {t: (args?: [...ArgTypes[]]) => string;};
'sm_InternationalPriority': {t: (args?: [...ArgTypes[]]) => string;};
'sm_InternationalRegular': {t: (args?: [...ArgTypes[]]) => string;};
'sm_PickupInStore': {t: (args?: [...ArgTypes[]]) => string;};
'saveAddress': {t: (args?: [...ArgTypes[]]) => string;};
'invalidProfile': {t: (args?: [...ArgTypes[]]) => string;};
'invalidProfileName': {t: (args?: [...ArgTypes[]]) => string;};
'selectExpMonth': {t: (args?: [...ArgTypes[]]) => string;};
'selectExpYear': {t: (args?: [...ArgTypes[]]) => string;};
'none': {t: (args?: [...ArgTypes[]]) => string;};
'selectShippingMethod': {t: (args?: [...ArgTypes[]]) => string;};
'selectPaymentMethod': {t: (args?: [...ArgTypes[]]) => string;};
'payMethods': {'VISA': {t: (args?: [...ArgTypes[]]) => string;};
'MasterCard': {t: (args?: [...ArgTypes[]]) => string;};
'AMEX': {t: (args?: [...ArgTypes[]]) => string;};
'COD': {t: (args?: [...ArgTypes[]]) => string;};
'ApplePay': {t: (args?: [...ArgTypes[]]) => string;};
'BillMeLater': {t: (args?: [...ArgTypes[]]) => string;};
'Check': {t: (args?: [...ArgTypes[]]) => string;};
'PayInStore': {t: (args?: [...ArgTypes[]]) => string;};
'PayLater': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'ChildPimCategories': {'title': {t: ({name}: TemplateArgs) => string;};
};
'Confirmation': {'ScheduleOrderCancel': {t: (args?: [...ArgTypes[]]) => string;};
'ScheduleOrderCancelNotification': {t: ({next}: TemplateArgs) => string;};
'CancelRecurringOrder': {t: (args?: [...ArgTypes[]]) => string;};
'SubmitButton': {t: (args?: [...ArgTypes[]]) => string;};
'CancelButton': {t: (args?: [...ArgTypes[]]) => string;};
};
'FeaturedCard': {'ShopNow': {t: (args?: [...ArgTypes[]]) => string;};
};
'ForgotPassword': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'EmailLabel': {t: (args?: [...ArgTypes[]]) => string;};
'EmailPlaceholder': {t: (args?: [...ArgTypes[]]) => string;};
'SubmitButton': {t: (args?: [...ArgTypes[]]) => string;};
'LogonIDLabel': {t: (args?: [...ArgTypes[]]) => string;};
'ContentText': {t: (args?: [...ArgTypes[]]) => string;};
'ContentTextLogonID': {t: (args?: [...ArgTypes[]]) => string;};
'ValidationCodeButton': {t: (args?: [...ArgTypes[]]) => string;};
'SendVerificationCodeButton': {t: (args?: [...ArgTypes[]]) => string;};
'CodeReceived': {t: (args?: [...ArgTypes[]]) => string;};
'AccountInfoRemember': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PriceDisplay': {'Labels': {'Pending': {t: (args?: [...ArgTypes[]]) => string;};
'PerUnit': {t: ({price}: TemplateArgs) => string;};
};
};
'error-message': {'ViewCart': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2020': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2050': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2070': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5060': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5066': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5210': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2190': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2080': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_LOGONID_ALREDY_EXIST_2030': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PASSWORDS_NOT_SAME_2080': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_USER_ALREADY_REGISTERD': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_MINIMUMLENGTH_PASSWORD_2200': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_MAXCONSECUTIVECHAR_PASSWORD_2210': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_MAXINTANCECHAR_PASSWORD_2220': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_MINIMUMLETTERS_PASSWORD_2230': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_MINIMUMDIGITS_PASSWORD_2240': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_USERIDMATCH_PASSWORD_2250': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_REUSEOLD_PASSWORD_2260': {t: (args?: [...ArgTypes[]]) => string;};
'_2040': {t: (args?: [...ArgTypes[]]) => string;};
'_2060': {t: (args?: [...ArgTypes[]]) => string;};
'_2070': {t: (args?: [...ArgTypes[]]) => string;};
'_2200': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_2210': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_2220': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_2230': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_2240': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_BAD_PARMS_2050': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_BAD_PARMS_2080': {t: (args?: [...ArgTypes[]]) => string;};
'_2250': {t: (args?: [...ArgTypes[]]) => string;};
'_2260': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_ERROR_2000': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_ERROR_2010': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_ERROR_2020': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_ERROR_2030': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PASSWORD_FAILED_CMN1019E': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PERSON_ACCOUNT_DISABLED_2110': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PERSON_ACCOUNT_LOCKED_OUT_2490': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_LOGIN_NOT_ALLOWED_NOW_2300': {t: (args?: [...ArgTypes[]]) => string;};
'_2400': {t: (args?: [...ArgTypes[]]) => string;};
'_2410': {t: (args?: [...ArgTypes[]]) => string;};
'_2420': {t: (args?: [...ArgTypes[]]) => string;};
'_2450': {t: (args?: [...ArgTypes[]]) => string;};
'_2430': {t: (args?: [...ArgTypes[]]) => string;};
'_2570': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_GENERIC_2110': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2000': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2010': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_BAD_PARMS_2010': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_MISSING_PARAM_2020': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2040': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2060': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2070': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2100': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_BAD_PARMS_1020': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5080': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5100': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5120': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5140': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5160': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5030': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_NICKNAME_ALREDY_EXIST_5040': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_CMD_MISSING_PARAM_2000': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2010': {t: (args?: [...ArgTypes[]]) => string;};
'ERR_PROMOTION_CODE_INVALID_-1200': {t: (args?: [...ArgTypes[]]) => string;};
'ERR_PROMOTION_NOT_AVAILABLE_AT_THIS_TIME_-2200': {t: (args?: [...ArgTypes[]]) => string;};
'ERR_PROMOTION_PER_SHOPPER_LIMIT_EXCEEDED_-1800': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'ERR_PROMOTION_OVERALL_LIMIT_EXCEEDED_-1900': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'ERR_PROMOTION_SYSTEM_ERR_-2700': {t: (args?: [...ArgTypes[]]) => string;};
'ERR_PROMOTION_CODE_DUPLICATED_-1150': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'ERR_PROMOTION_UNKNOWN_ERR_-2600': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_FORMAT_PROMOCODES_NOT_CORRECT_-2820': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PARAMETER_MISSING_-2810': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_FORMAT_ORDERIDS_NOT_CORRECT_-2830': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PAY_CARD_NUMBER_MISSING_190': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PAY_CARD_BRAND_MISSING_190': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CREATE_PAYMENT_METHOD': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CREATE_PAYMENT_METHOD_1007': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PAY_CARD_NUMBER_INVALID_1005': {t: (args?: [...ArgTypes[]]) => string;};
'_DBG_API_PAY_BAD_LEN_OR_PREFIX_1005': {t: (args?: [...ArgTypes[]]) => string;};
'_DBG_API_DO_PAYMENT_BAD_XDATE_1006': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PAY_EXPIRY_DATE_INVALID_1006': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CREATE_PAYMENT_METHOD_CMN1112E': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_RDN_ALREADY_EXIST': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_parentMemberId': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_parentMember': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_org_orgEntityName': {t: (args?: [...ArgTypes[]]) => string;};
'_API_BAD_INV_2': {t: (args: [ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_API_BAD_INV_DELIVERY': {t: (args?: [...ArgTypes[]]) => string;};
'_API_BAD_INV_PICKUP': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_FINDING_ORDER_230': {t: (args?: [...ArgTypes[]]) => string;};
'ERR_INTERNAL_SERVER_ERROR_CWXFR0230E': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_REMOTE_EXCEPTION_CMN0412E': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_REG_DUPE_ID_CMN0114S': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_FINDER_EXCEPTION_CMN0411E': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2195': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PASSWORD_EXPIRED_2170': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_INVALID_NAME_CMN1652E': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_GOOGLE_MAP_NOT_SUPPORT': {t: (args?: [...ArgTypes[]]) => string;};
'HTTP_404_NOT_FOUND': {t: (args?: [...ArgTypes[]]) => string;};
'HTTP_500_INTERNAL_SERVER_ERROR': {t: (args?: [...ArgTypes[]]) => string;};
'NoCopyOrderItems': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CATALOG_ENTRY_MARK_FOR_DELETE_CWXCO3006E': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
};
'success-message': {'ITEM_ADD_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
'ViewCart': {t: (args?: [...ArgTypes[]]) => string;};
'COPY_CART_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrderCancelled': {t: (args?: [...ArgTypes[]]) => string;};
'ADD_ADDRESS_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'EDIT_ADDRESS_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_ADDRESS_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'RESEND_VERIFICATION_CODE': {t: (args?: [...ArgTypes[]]) => string;};
'PASSWORD_RESET_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
'CREATE_CHECKOUT_PROFILE_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'COPY_ORDER_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
'CREATE_ORDER_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_ORDER_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'CREATE_REQUISITIONLIST_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_REQUISITIONLIST_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'UPDATED_REQUISITIONLIST_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
'DeletedCheckoutProfile': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'UpdatedCheckoutProfile': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'deletedSuccessfully': {t: ({n}: TemplateArgs) => string;};
'addedItemSuccessfully': {t: ({v}: TemplateArgs) => string;};
'addedNSuccessfully': {t: ({v}: TemplateArgs) => string;};
'addedItemRLSuccessfully': {t: ({v}: TemplateArgs) => string;};
'addedNRLSuccessfully': {t: ({v}: TemplateArgs) => string;};
'deletedItemListSuccessfully': {t: ({count}: TemplateArgs) => string;};
'CREATE_WISHLIST_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_WISHLIST_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_WISHLIST_ITEM_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_WISHLIST_ITEMS_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'WISHLIST_ADD_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'addItemListSuccessfully': {t: ({v}: TemplateArgs) => string;};
'addNItemsSuc': {t: ({n}: TemplateArgs) => string;};
'UPDATE_WISHLIST_NAME_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'ITEM_TO_CART': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'ITEMS_N_TO_CART': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'CopyOrder': {t: (args?: [...ArgTypes[]]) => string;};
'PartialCopyOrder': {t: (args?: [...ArgTypes[]]) => string;};
'INVENTORY_NOTIFY_THANKYOU': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrderBillingInfo': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'BillAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderDetails': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {'Back': {t: (args?: [...ArgTypes[]]) => string;};
'Next': {t: (args?: [...ArgTypes[]]) => string;};
'NextRecurringOrder': {t: (args?: [...ArgTypes[]]) => string;};
'BackCart': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'PaymentDetails': {t: (args?: [...ArgTypes[]]) => string;};
'OrderSummary': {t: (args?: [...ArgTypes[]]) => string;};
'Empty': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderDiscountSummary': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'Total': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderItemTable': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'ItemDetails': {t: (args?: [...ArgTypes[]]) => string;};
'SKU': {t: (args?: [...ArgTypes[]]) => string;};
'Availability': {t: (args?: [...ArgTypes[]]) => string;};
'Quantity': {t: (args?: [...ArgTypes[]]) => string;};
'Price': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {t: (args?: [...ArgTypes[]]) => string;};
'Gift': {t: (args?: [...ArgTypes[]]) => string;};
'NonRecurring': {t: (args?: [...ArgTypes[]]) => string;};
'PageSizeLabel': {t: (args?: [...ArgTypes[]]) => string;};
'FirstPage': {t: (args?: [...ArgTypes[]]) => string;};
'LastPage': {t: (args?: [...ArgTypes[]]) => string;};
'PreviousPage': {t: (args?: [...ArgTypes[]]) => string;};
'NextPage': {t: (args?: [...ArgTypes[]]) => string;};
'RowCount': {t: (args?: [...ArgTypes[]]) => string;};
'showAttrs': {t: (args?: [...ArgTypes[]]) => string;};
'hideAttrs': {t: (args?: [...ArgTypes[]]) => string;};
'Status': {t: (args?: [...ArgTypes[]]) => string;};
'RemoveFromCart': {t: (args?: [...ArgTypes[]]) => string;};
'Empty': {t: (args?: [...ArgTypes[]]) => string;};
'ProductThumbnail': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'Delete': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderLinks': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistoryText': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistoryDescription': {t: (args?: [...ArgTypes[]]) => string;};
'InprogressOrdersText': {t: (args?: [...ArgTypes[]]) => string;};
'InprogressOrdersDescription': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrdersText': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrdersDescription': {t: (args?: [...ArgTypes[]]) => string;};
'ApproveOrdersText': {t: (args?: [...ArgTypes[]]) => string;};
'ApproveOrdersDescription': {t: (args?: [...ArgTypes[]]) => string;};
'RequisitionListsText': {t: (args?: [...ArgTypes[]]) => string;};
'RequisitionListsDescription': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrderPaymentInfo': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'BillMethod': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderShippingInfo': {'Labels': {'ShipAddress': {t: (args?: [...ArgTypes[]]) => string;};
'ShipMethod': {t: (args?: [...ArgTypes[]]) => string;};
'CartDetails': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingDetails': {t: (args?: [...ArgTypes[]]) => string;};
'ShipmentGroup': {t: ({index}: TemplateArgs) => string;};
'HideGroupDetails': {t: (args?: [...ArgTypes[]]) => string;};
'ShowGroupDetails': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderTotalSummary': {'Labels': {'Subtotal': {t: (args?: [...ArgTypes[]]) => string;};
'Tax': {t: (args?: [...ArgTypes[]]) => string;};
'Shipping': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingTax': {t: (args?: [...ArgTypes[]]) => string;};
'Discount': {t: (args?: [...ArgTypes[]]) => string;};
'Total': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PaymentMethodContainer': {'Actions': {'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'Done': {t: (args?: [...ArgTypes[]]) => string;};
'SaveAndAdd': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PaymentInfoCard': {'Title': {t: ({number}: TemplateArgs) => string;};
'Labels': {'CreditCard': {t: (args?: [...ArgTypes[]]) => string;};
'AmountToPay': {t: (args?: [...ArgTypes[]]) => string;};
'ExpiryDate': {t: ({month, year}: TemplateArgs) => string;};
'BillingAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'Edit': {t: (args?: [...ArgTypes[]]) => string;};
'Delete': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PaymentInfoList': {'Title': {'PayMethod': {t: (args?: [...ArgTypes[]]) => string;};
'WithOrderTotal': {t: (args?: [...ArgTypes[]]) => string;};
};
'Msgs': {'PayMethodRequired': {t: (args?: [...ArgTypes[]]) => string;};
'InsufficientPayment': {t: ({grandTotal}: TemplateArgs) => string;};
'CardDetailsUpdate': {t: (args?: [...ArgTypes[]]) => string;};
'PayMethodNote': {t: (args?: [...ArgTypes[]]) => string;};
'PaymentAmountError': {t: ({paymentsTotal, grandTotal}: TemplateArgs) => string;};
'notValidForMulti': {t: ({type}: TemplateArgs) => string;};
};
'Actions': {'AddPayMethod': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PaymentMethodSelection': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'CreditCard': {t: ({cards}: TemplateArgs) => string;};
'CVV': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidCardNumber': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
'SelectPaymentMethod': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'COD': {t: (args?: [...ArgTypes[]]) => string;};
'CreditCard': {t: (args?: [...ArgTypes[]]) => string;};
'CCNumber': {t: (args?: [...ArgTypes[]]) => string;};
'ExpiryDate': {t: (args?: [...ArgTypes[]]) => string;};
'CVV': {t: (args?: [...ArgTypes[]]) => string;};
'OrderTotal': {t: (args?: [...ArgTypes[]]) => string;};
'RemainingAmount': {t: (args?: [...ArgTypes[]]) => string;};
'AmountToPay': {t: (args?: [...ArgTypes[]]) => string;};
'PayTooMuch': {t: (args?: [...ArgTypes[]]) => string;};
'ExpiryMonth': {t: (args?: [...ArgTypes[]]) => string;};
'ExpiryYear': {t: (args?: [...ArgTypes[]]) => string;};
'Choose': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'PayRemaining': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PersonalInformation': {'UpdateSuccessful': {t: (args?: [...ArgTypes[]]) => string;};
'AccountInformation': {t: (args?: [...ArgTypes[]]) => string;};
'Edit': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'ChangePassword': {t: (args?: [...ArgTypes[]]) => string;};
'EditAccountInformation': {t: (args?: [...ArgTypes[]]) => string;};
'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'PhoneNumber': {t: (args?: [...ArgTypes[]]) => string;};
'PreferredCurrency': {t: (args?: [...ArgTypes[]]) => string;};
'Gender': {t: (args?: [...ArgTypes[]]) => string;};
'SelectGender': {t: (args?: [...ArgTypes[]]) => string;};
'BirthYear': {t: (args?: [...ArgTypes[]]) => string;};
'BirthMonth': {t: (args?: [...ArgTypes[]]) => string;};
'BirthDate': {t: (args?: [...ArgTypes[]]) => string;};
'SelectYear': {t: (args?: [...ArgTypes[]]) => string;};
'SelectMonth': {t: (args?: [...ArgTypes[]]) => string;};
'SelectDate': {t: (args?: [...ArgTypes[]]) => string;};
'DateOfBirthEmpty': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'SaveChanges': {t: (args?: [...ArgTypes[]]) => string;};
'CurrentPassword': {t: (args?: [...ArgTypes[]]) => string;};
'NewPassword': {t: (args?: [...ArgTypes[]]) => string;};
'VerifyPassword': {t: (args?: [...ArgTypes[]]) => string;};
'SignOutButton': {t: (args?: [...ArgTypes[]]) => string;};
'actingAs': {t: (args?: [...ArgTypes[]]) => string;};
'resetPass': {t: (args?: [...ArgTypes[]]) => string;};
'resetSure': {t: (args?: [...ArgTypes[]]) => string;};
'resetNo': {t: (args?: [...ArgTypes[]]) => string;};
'resetYes': {t: (args?: [...ArgTypes[]]) => string;};
'stopActing': {t: (args?: [...ArgTypes[]]) => string;};
'guestUser': {t: (args?: [...ArgTypes[]]) => string;};
'register': {t: (args?: [...ArgTypes[]]) => string;};
'enableUser': {t: (args?: [...ArgTypes[]]) => string;};
'disableUser': {t: (args?: [...ArgTypes[]]) => string;};
'disabledMessage': {t: (args?: [...ArgTypes[]]) => string;};
};
'ProductFilter': {'Labels': {'filterBy': {t: (args?: [...ArgTypes[]]) => string;};
'noFilters': {t: (args?: [...ArgTypes[]]) => string;};
'price': {t: (args?: [...ArgTypes[]]) => string;};
'minPrice': {t: (args?: [...ArgTypes[]]) => string;};
'maxPrice': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'showMore': {t: (args?: [...ArgTypes[]]) => string;};
'showLess': {t: (args?: [...ArgTypes[]]) => string;};
'Filter': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'ProductGrid': {'Labels': {'filteredBy': {t: (args?: [...ArgTypes[]]) => string;};
'productFound': {t: ({count}: TemplateArgs) => string;};
'productSearchFound': {t: ({count, searchTerm}: TemplateArgs) => string;};
'noMatches': {t: ({searchTerm}: TemplateArgs) => string;};
'searchAgain': {t: ({searchTerm}: TemplateArgs) => string;};
'suggestion': {t: (args?: [...ArgTypes[]]) => string;};
'noProductsFound': {t: ({keyword}: TemplateArgs) => string;};
'noProductsFoundForFilter': {t: (args?: [...ArgTypes[]]) => string;};
'sortBy': {t: (args?: [...ArgTypes[]]) => string;};
'gtmItemList': {t: (args?: [...ArgTypes[]]) => string;};
'gtmSearchResults': {t: ({term}: TemplateArgs) => string;};
};
'Actions': {'clearAll': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PurchaseOrderNumber': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'PONumberRequired': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'PONumber': {t: (args?: [...ArgTypes[]]) => string;};
'display': {t: ({poNumber}: TemplateArgs) => string;};
};
};
'PurchaseOrderSelection': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'PONumber': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'RecurringOrderInfo': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'RecurringOrderNumber': {t: (args?: [...ArgTypes[]]) => string;};
'OrderSchedule': {t: (args?: [...ArgTypes[]]) => string;};
'StartDate': {t: (args?: [...ArgTypes[]]) => string;};
'NextDelivery': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistoryButton': {t: ({orderId}: TemplateArgs) => string;};
};
};
'RegistrationLayout': {'NewCustomer': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'Account': {t: (args?: [...ArgTypes[]]) => string;};
'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'LogonId': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'emailPlaceholder': {t: (args?: [...ArgTypes[]]) => string;};
'Phone': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'VerifyPassword': {t: (args?: [...ArgTypes[]]) => string;};
'TextContent': {t: (args?: [...ArgTypes[]]) => string;};
'Register': {t: (args?: [...ArgTypes[]]) => string;};
'RegisterComplete': {t: (args?: [...ArgTypes[]]) => string;};
'RememberMe': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'RegistrationB2BLayout': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {'BuyerReg': {t: (args?: [...ArgTypes[]]) => string;};
'OrgReg': {t: (args?: [...ArgTypes[]]) => string;};
'AddBuyer': {t: (args?: [...ArgTypes[]]) => string;};
'AddOrg': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'ResetPassword': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'ValidationCodeLabel': {t: (args?: [...ArgTypes[]]) => string;};
'NewPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'VerifyPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SubmitButton': {t: (args?: [...ArgTypes[]]) => string;};
'ContentText': {t: (args?: [...ArgTypes[]]) => string;};
'ContentTextLogonID': {t: (args?: [...ArgTypes[]]) => string;};
'EmailLabel': {t: (args?: [...ArgTypes[]]) => string;};
'EmailPlaceholder': {t: (args?: [...ArgTypes[]]) => string;};
'LogonIDLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SuccessMsg': {t: (args?: [...ArgTypes[]]) => string;};
'PasswordNotReceived': {t: (args?: [...ArgTypes[]]) => string;};
'ResendVerificationCode': {t: (args?: [...ArgTypes[]]) => string;};
'AccountInfoRemember': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'SearchBar': {'SearchField': {t: (args?: [...ArgTypes[]]) => string;};
'KeywordTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CategoryTitle': {t: (args?: [...ArgTypes[]]) => string;};
'BrandTitle': {t: (args?: [...ArgTypes[]]) => string;};
'SellerTitle': {t: (args?: [...ArgTypes[]]) => string;};
'Clear': {t: (args?: [...ArgTypes[]]) => string;};
'SuggestedKeywords': {t: (args?: [...ArgTypes[]]) => string;};
'SuggestedProducts': {t: (args?: [...ArgTypes[]]) => string;};
};
'SessionError': {'TimeoutTitle': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidTitle': {t: (args?: [...ArgTypes[]]) => string;};
'GenericTitle': {t: (args?: [...ArgTypes[]]) => string;};
'TimeoutMsg': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidMsg': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidMsgB2C': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'SubmitButton': {t: (args?: [...ArgTypes[]]) => string;};
'CancelButton': {t: (args?: [...ArgTypes[]]) => string;};
'PartialAuthError': {t: (args?: [...ArgTypes[]]) => string;};
'ActionIncomplete': {t: (args?: [...ArgTypes[]]) => string;};
};
'SignIn': {'ReturningCustomer': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'ChangePassword': {t: (args?: [...ArgTypes[]]) => string;};
'CurrentPassword': {t: (args?: [...ArgTypes[]]) => string;};
'PasswordVerify': {t: (args?: [...ArgTypes[]]) => string;};
'RememberMe': {t: (args?: [...ArgTypes[]]) => string;};
'SignInButton': {t: (args?: [...ArgTypes[]]) => string;};
'SignInAndCheckoutButton': {t: (args?: [...ArgTypes[]]) => string;};
'SignInAndCheckoutTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutAsGuestMsg': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutAsGuestButton': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'Submit': {t: (args?: [...ArgTypes[]]) => string;};
'ForgotPassword': {t: (args?: [...ArgTypes[]]) => string;};
'noAccount': {t: (args?: [...ArgTypes[]]) => string;};
'registerNow': {t: (args?: [...ArgTypes[]]) => string;};
'emailPlaceholder': {t: (args?: [...ArgTypes[]]) => string;};
'Label': {'LogonId': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'rememberMe': {t: (args?: [...ArgTypes[]]) => string;};
};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
'PasswordNotMatch': {t: (args?: [...ArgTypes[]]) => string;};
'OldPasswordReuse': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'Pickup': {'PickupOrderMsg': {t: (args?: [...ArgTypes[]]) => string;};
'IllPickupRadioLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SomeoneElseRadioLabel': {t: (args?: [...ArgTypes[]]) => string;};
'HowPickupOrderMsg': {t: (args?: [...ArgTypes[]]) => string;};
'EmailMsg': {t: (args?: [...ArgTypes[]]) => string;};
'ContinueMsg': {t: (args?: [...ArgTypes[]]) => string;};
'BackButton': {t: (args?: [...ArgTypes[]]) => string;};
'ProceedPayment': {t: (args?: [...ArgTypes[]]) => string;};
'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'Phone': {t: (args?: [...ArgTypes[]]) => string;};
'ContinuePickupDetails': {t: (args?: [...ArgTypes[]]) => string;};
'BackToPickupStore': {t: (args?: [...ArgTypes[]]) => string;};
'ContinueCheckout': {t: (args?: [...ArgTypes[]]) => string;};
'PickupPersonFullName': {t: (args?: [...ArgTypes[]]) => string;};
'PickupPersonEmail': {t: (args?: [...ArgTypes[]]) => string;};
'BuyerFullName': {t: (args?: [...ArgTypes[]]) => string;};
'PickupContactTitle': {t: (args?: [...ArgTypes[]]) => string;};
'PickupStoreTitle': {t: (args?: [...ArgTypes[]]) => string;};
'ContactDetailsTitle': {t: (args?: [...ArgTypes[]]) => string;};
'PickupDetailsTitle': {t: (args?: [...ArgTypes[]]) => string;};
};
'CommerceEnvironment': {'listSettings': {'relevance': {t: (args?: [...ArgTypes[]]) => string;};
'brands': {t: (args?: [...ArgTypes[]]) => string;};
'name': {t: (args?: [...ArgTypes[]]) => string;};
'priceLowToHigh': {t: (args?: [...ArgTypes[]]) => string;};
'priceHighToLow': {t: (args?: [...ArgTypes[]]) => string;};
};
'inventoryStatus': {'Available': {t: (args?: [...ArgTypes[]]) => string;};
'Allocated': {t: (args?: [...ArgTypes[]]) => string;};
'Unallocated': {t: (args?: [...ArgTypes[]]) => string;};
'Backordered': {t: (args?: [...ArgTypes[]]) => string;};
'OOS': {t: (args?: [...ArgTypes[]]) => string;};
'NA': {t: (args?: [...ArgTypes[]]) => string;};
};
'inventoryStatusOnline': {'Available': {t: (args?: [...ArgTypes[]]) => string;};
'Allocated': {t: (args?: [...ArgTypes[]]) => string;};
'Unallocated': {t: (args?: [...ArgTypes[]]) => string;};
'Backordered': {t: (args?: [...ArgTypes[]]) => string;};
'OOS': {t: (args?: [...ArgTypes[]]) => string;};
'NA': {t: (args?: [...ArgTypes[]]) => string;};
};
'inventoryStatusStore': {'Available': {t: ({store}: TemplateArgs) => string;};
'Allocated': {t: ({store}: TemplateArgs) => string;};
'Unallocated': {t: ({store}: TemplateArgs) => string;};
'Backordered': {t: ({store}: TemplateArgs) => string;};
'OOS': {t: ({store}: TemplateArgs) => string;};
'NA': {t: ({store}: TemplateArgs) => string;};
};
'recurringOrderFrequency': {'Everyday': {t: (args?: [...ArgTypes[]]) => string;};
'EveryWeek': {t: (args?: [...ArgTypes[]]) => string;};
'EveryTwoWeeks': {t: (args?: [...ArgTypes[]]) => string;};
'EveryThreeWeeks': {t: (args?: [...ArgTypes[]]) => string;};
'EveryFourWeeks': {t: (args?: [...ArgTypes[]]) => string;};
};
'languagePopUp': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Message': {t: ({language}: TemplateArgs) => string;};
'Yes': {t: (args?: [...ArgTypes[]]) => string;};
'No': {t: (args?: [...ArgTypes[]]) => string;};
};
'currency': {'ROL': {t: (args?: [...ArgTypes[]]) => string;};
'RON': {t: (args?: [...ArgTypes[]]) => string;};
'ARS': {t: (args?: [...ArgTypes[]]) => string;};
'ATS': {t: (args?: [...ArgTypes[]]) => string;};
'AUD': {t: (args?: [...ArgTypes[]]) => string;};
'BEF': {t: (args?: [...ArgTypes[]]) => string;};
'BRL': {t: (args?: [...ArgTypes[]]) => string;};
'CAD': {t: (args?: [...ArgTypes[]]) => string;};
'CNY': {t: (args?: [...ArgTypes[]]) => string;};
'DEM': {t: (args?: [...ArgTypes[]]) => string;};
'ESP': {t: (args?: [...ArgTypes[]]) => string;};
'EUR': {t: (args?: [...ArgTypes[]]) => string;};
'FRF': {t: (args?: [...ArgTypes[]]) => string;};
'GBP': {t: (args?: [...ArgTypes[]]) => string;};
'HKD': {t: (args?: [...ArgTypes[]]) => string;};
'ITL': {t: (args?: [...ArgTypes[]]) => string;};
'JPY': {t: (args?: [...ArgTypes[]]) => string;};
'KRW': {t: (args?: [...ArgTypes[]]) => string;};
'SGD': {t: (args?: [...ArgTypes[]]) => string;};
'TWD': {t: (args?: [...ArgTypes[]]) => string;};
'USD': {t: (args?: [...ArgTypes[]]) => string;};
'ZAR': {t: (args?: [...ArgTypes[]]) => string;};
'FIM': {t: (args?: [...ArgTypes[]]) => string;};
'IEP': {t: (args?: [...ArgTypes[]]) => string;};
'LUF': {t: (args?: [...ArgTypes[]]) => string;};
'NLG': {t: (args?: [...ArgTypes[]]) => string;};
'PTE': {t: (args?: [...ArgTypes[]]) => string;};
'NOK': {t: (args?: [...ArgTypes[]]) => string;};
'SEK': {t: (args?: [...ArgTypes[]]) => string;};
'DKK': {t: (args?: [...ArgTypes[]]) => string;};
'CHF': {t: (args?: [...ArgTypes[]]) => string;};
'PLN': {t: (args?: [...ArgTypes[]]) => string;};
'RUB': {t: (args?: [...ArgTypes[]]) => string;};
'EGP': {t: (args?: [...ArgTypes[]]) => string;};
};
'language': {'-1': {t: (args?: [...ArgTypes[]]) => string;};
'-2': {t: (args?: [...ArgTypes[]]) => string;};
'-3': {t: (args?: [...ArgTypes[]]) => string;};
'-4': {t: (args?: [...ArgTypes[]]) => string;};
'-5': {t: (args?: [...ArgTypes[]]) => string;};
'-6': {t: (args?: [...ArgTypes[]]) => string;};
'-7': {t: (args?: [...ArgTypes[]]) => string;};
'-8': {t: (args?: [...ArgTypes[]]) => string;};
'-10': {t: (args?: [...ArgTypes[]]) => string;};
'-21': {t: (args?: [...ArgTypes[]]) => string;};
'-20': {t: (args?: [...ArgTypes[]]) => string;};
'-9': {t: (args?: [...ArgTypes[]]) => string;};
'-22': {t: (args?: [...ArgTypes[]]) => string;};
'-23': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'RequisitionLists': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'CreateNewRequisitionList': {t: (args?: [...ArgTypes[]]) => string;};
'NewRequisitionName': {t: (args?: [...ArgTypes[]]) => string;};
'CreateList': {t: (args?: [...ArgTypes[]]) => string;};
'PrivateList': {t: (args?: [...ArgTypes[]]) => string;};
'SharedList': {t: (args?: [...ArgTypes[]]) => string;};
'Visibility': {t: (args?: [...ArgTypes[]]) => string;};
'UploadRequisitionList': {t: (args?: [...ArgTypes[]]) => string;};
'DownloadInstructionsWithAnExample': {t: (args?: [...ArgTypes[]]) => string;};
'ViewUploadLogs': {t: (args?: [...ArgTypes[]]) => string;};
'NoViewRecord': {t: (args?: [...ArgTypes[]]) => string;};
'NoRecord': {t: (args?: [...ArgTypes[]]) => string;};
'Browse': {t: (args?: [...ArgTypes[]]) => string;};
'SelectedFile': {t: ({file}: TemplateArgs) => string;};
'Columns': {'ListName': {t: (args?: [...ArgTypes[]]) => string;};
'CreatedBy': {t: (args?: [...ArgTypes[]]) => string;};
'Items': {t: (args?: [...ArgTypes[]]) => string;};
'DateCreated': {t: (args?: [...ArgTypes[]]) => string;};
'LastUpdate': {t: (args?: [...ArgTypes[]]) => string;};
'Type': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {t: (args?: [...ArgTypes[]]) => string;};
};
'CreatedBy': {t: ({firstName, lastName}: TemplateArgs) => string;};
'Type': {'Y': {t: (args?: [...ArgTypes[]]) => string;};
'Z': {t: (args?: [...ArgTypes[]]) => string;};
};
'DeleteDialogTitle': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteDialogHeading': {t: (args?: [...ArgTypes[]]) => string;};
'CopyTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CopyMessage': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidListName': {t: (args?: [...ArgTypes[]]) => string;};
'searchPh': {t: (args?: [...ArgTypes[]]) => string;};
'SuccessfullyCreatedRequisitionList': {t: ({name}: TemplateArgs) => string;};
'UploadLog': {t: (args?: [...ArgTypes[]]) => string;};
'UploadedFile': {t: (args?: [...ArgTypes[]]) => string;};
'Status': {t: (args?: [...ArgTypes[]]) => string;};
'UploadTime': {t: (args?: [...ArgTypes[]]) => string;};
'ViewLog': {t: (args?: [...ArgTypes[]]) => string;};
'UpdNofM': {t: ({n, m}: TemplateArgs) => string;};
'PartialCompleteStatusTop': {t: (args?: [...ArgTypes[]]) => string;};
'FailedStatusTop': {t: (args?: [...ArgTypes[]]) => string;};
'PartialCompleteStatusBottom': {t: (args?: [...ArgTypes[]]) => string;};
'FailedStatusBottom': {t: (args?: [...ArgTypes[]]) => string;};
'LineNumber': {t: (args?: [...ArgTypes[]]) => string;};
'SKU': {t: (args?: [...ArgTypes[]]) => string;};
'PartiallyUploaded': {t: (args?: [...ArgTypes[]]) => string;};
'Processing': {t: (args?: [...ArgTypes[]]) => string;};
'Uploaded': {t: (args?: [...ArgTypes[]]) => string;};
'UploadFailed': {t: (args?: [...ArgTypes[]]) => string;};
'search': {t: (args?: [...ArgTypes[]]) => string;};
'Add': {t: (args?: [...ArgTypes[]]) => string;};
'SKUSearch': {t: (args?: [...ArgTypes[]]) => string;};
'Quantity': {t: (args?: [...ArgTypes[]]) => string;};
'NoSKUFound': {t: (args?: [...ArgTypes[]]) => string;};
'EmptyRL': {t: (args?: [...ArgTypes[]]) => string;};
'Refresh': {t: (args?: [...ArgTypes[]]) => string;};
'CopyList': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteList': {t: (args?: [...ArgTypes[]]) => string;};
'nameForCopiedList': {t: (args?: [...ArgTypes[]]) => string;};
'AddListToCart': {t: (args?: [...ArgTypes[]]) => string;};
'SelectUploadFile': {t: (args?: [...ArgTypes[]]) => string;};
};
'RequisitionListItems': {'noItems': {t: (args?: [...ArgTypes[]]) => string;};
'createdBy': {t: (args?: [...ArgTypes[]]) => string;};
'Visibility': {t: (args?: [...ArgTypes[]]) => string;};
'orderType_SRL': {t: (args?: [...ArgTypes[]]) => string;};
'orderType_PRL': {t: (args?: [...ArgTypes[]]) => string;};
'each': {t: (args?: [...ArgTypes[]]) => string;};
'orderItem': {t: (args?: [...ArgTypes[]]) => string;};
'manufacturer': {t: (args?: [...ArgTypes[]]) => string;};
'quantity': {t: (args?: [...ArgTypes[]]) => string;};
'actions': {t: (args?: [...ArgTypes[]]) => string;};
'selectAllProducts': {t: (args?: [...ArgTypes[]]) => string;};
'editListDetails': {t: (args?: [...ArgTypes[]]) => string;};
'addSelectedToCart': {t: (args?: [...ArgTypes[]]) => string;};
'deleteSelected': {t: (args?: [...ArgTypes[]]) => string;};
'nProductsSel': {t: ({n}: TemplateArgs) => string;};
'addProds': {t: (args?: [...ArgTypes[]]) => string;};
'cancel': {t: (args?: [...ArgTypes[]]) => string;};
'saveChanges': {t: (args?: [...ArgTypes[]]) => string;};
'addListToCart': {t: (args?: [...ArgTypes[]]) => string;};
'addToCart': {t: (args?: [...ArgTypes[]]) => string;};
'deleteItem': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteDialogTitle': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteDialogHeading': {t: (args?: [...ArgTypes[]]) => string;};
'Confirm': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'Select': {t: (args?: [...ArgTypes[]]) => string;};
'ShowAttributes': {t: (args?: [...ArgTypes[]]) => string;};
'HideAttributes': {t: (args?: [...ArgTypes[]]) => string;};
};
'InprogressOrders': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'CreateNewOrder': {t: (args?: [...ArgTypes[]]) => string;};
'NewOrderName': {t: (args?: [...ArgTypes[]]) => string;};
'CreateOrder': {t: (args?: [...ArgTypes[]]) => string;};
'PrivateOrder': {t: (args?: [...ArgTypes[]]) => string;};
'SharedOrder': {t: (args?: [...ArgTypes[]]) => string;};
'Columns': {'Active': {t: (args?: [...ArgTypes[]]) => string;};
'OrderId': {t: (args?: [...ArgTypes[]]) => string;};
'Name': {t: (args?: [...ArgTypes[]]) => string;};
'LastUpdated': {t: (args?: [...ArgTypes[]]) => string;};
'TotalPrice': {t: (args?: [...ArgTypes[]]) => string;};
'Type': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {t: (args?: [...ArgTypes[]]) => string;};
};
'Status': {'ORD': {t: (args?: [...ArgTypes[]]) => string;};
'SHR': {t: (args?: [...ArgTypes[]]) => string;};
};
'DeleteDialogTitle': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteDialogHeading': {t: (args?: [...ArgTypes[]]) => string;};
'CopyTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CopyMessage': {t: (args?: [...ArgTypes[]]) => string;};
'CurrentCart': {t: ({orderId}: TemplateArgs) => string;};
'InvalidOrderName': {t: (args?: [...ArgTypes[]]) => string;};
'searchPh': {t: (args?: [...ArgTypes[]]) => string;};
'NotActiveOrderMessage': {t: (args?: [...ArgTypes[]]) => string;};
};
'InprogressItems': {'orderItem': {t: (args?: [...ArgTypes[]]) => string;};
'creationDate': {t: (args?: [...ArgTypes[]]) => string;};
'quantity': {t: (args?: [...ArgTypes[]]) => string;};
'contributor': {t: (args?: [...ArgTypes[]]) => string;};
'each': {t: (args?: [...ArgTypes[]]) => string;};
'actions': {t: (args?: [...ArgTypes[]]) => string;};
'inprogressOrders': {t: (args?: [...ArgTypes[]]) => string;};
'createdBy': {t: (args?: [...ArgTypes[]]) => string;};
'lastUpdated': {t: (args?: [...ArgTypes[]]) => string;};
'totalPrice': {t: (args?: [...ArgTypes[]]) => string;};
'visibility': {t: (args?: [...ArgTypes[]]) => string;};
'orderType_SHR': {t: (args?: [...ArgTypes[]]) => string;};
'orderType_ORD': {t: (args?: [...ArgTypes[]]) => string;};
'proceed': {t: (args?: [...ArgTypes[]]) => string;};
'addProds': {t: (args?: [...ArgTypes[]]) => string;};
'privateList': {t: (args?: [...ArgTypes[]]) => string;};
'sharedList': {t: (args?: [...ArgTypes[]]) => string;};
'nProductsSel': {t: ({n}: TemplateArgs) => string;};
'addSelToCart': {t: (args?: [...ArgTypes[]]) => string;};
'deleteSelected': {t: (args?: [...ArgTypes[]]) => string;};
'noItems': {t: (args?: [...ArgTypes[]]) => string;};
'nameForCopiedOrder': {t: (args?: [...ArgTypes[]]) => string;};
};
'commonTable': {'ofTotalCount': {t: ({from, to, total}: TemplateArgs) => string;};
'pageSz': {'five': {t: (args?: [...ArgTypes[]]) => string;};
'ten': {t: (args?: [...ArgTypes[]]) => string;};
'twenty': {t: (args?: [...ArgTypes[]]) => string;};
};
'rows': {t: (args?: [...ArgTypes[]]) => string;};
'paginationText': {t: ({from, total}: TemplateArgs) => string;};
};
'WishList': {'CreateWishListTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CreateList': {t: (args?: [...ArgTypes[]]) => string;};
'WishListName': {t: (args?: [...ArgTypes[]]) => string;};
'Title': {t: (args?: [...ArgTypes[]]) => string;};
'NoWishListMessage': {t: (args?: [...ArgTypes[]]) => string;};
'WishListEmptyMessage': {t: (args?: [...ArgTypes[]]) => string;};
'ViewList': {t: (args?: [...ArgTypes[]]) => string;};
'WishListItemsMessage': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidWishListName': {t: (args?: [...ArgTypes[]]) => string;};
'Confirm': {t: (args?: [...ArgTypes[]]) => string;};
'ConfirmDelete': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'RemoveItemsTitle': {t: (args?: [...ArgTypes[]]) => string;};
'ConfirmDeleteSelectedMsg': {t: (args?: [...ArgTypes[]]) => string;};
'ConfirmDeleteMsg': {t: (args?: [...ArgTypes[]]) => string;};
'ConfirmDeleteTitle': {t: (args?: [...ArgTypes[]]) => string;};
'EmptyWishListMsg': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {'AddToCart': {t: (args?: [...ArgTypes[]]) => string;};
'SelectAll': {t: (args?: [...ArgTypes[]]) => string;};
'EditList': {t: (args?: [...ArgTypes[]]) => string;};
'Delete': {t: (args?: [...ArgTypes[]]) => string;};
'Save': {t: (args?: [...ArgTypes[]]) => string;};
'AddSelectedToCart': {t: (args?: [...ArgTypes[]]) => string;};
'DeletedSelected': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteList': {t: (args?: [...ArgTypes[]]) => string;};
};
'ProductSelected': {t: ({n}: TemplateArgs) => string;};
};
'OrderMethod': {'Message': {t: (args?: [...ArgTypes[]]) => string;};
'Delivery': {t: (args?: [...ArgTypes[]]) => string;};
'Pickup': {t: (args?: [...ArgTypes[]]) => string;};
};
'compare': {'compare': {t: (args?: [...ArgTypes[]]) => string;};
'start': {t: ({n}: TemplateArgs) => string;};
'tnail': {t: (args?: [...ArgTypes[]]) => string;};
'notavl': {t: (args?: [...ArgTypes[]]) => string;};
'price': {t: (args?: [...ArgTypes[]]) => string;};
'tabletOrDesk': {t: (args?: [...ArgTypes[]]) => string;};
'goBack': {t: (args?: [...ArgTypes[]]) => string;};
'addAnother': {t: (args?: [...ArgTypes[]]) => string;};
'prodComp': {t: (args?: [...ArgTypes[]]) => string;};
'boxNofM': {t: ({n, m}: TemplateArgs) => string;};
'clearSel': {t: (args?: [...ArgTypes[]]) => string;};
'hide': {t: (args?: [...ArgTypes[]]) => string;};
'expand': {t: (args?: [...ArgTypes[]]) => string;};
'compSel': {t: (args?: [...ArgTypes[]]) => string;};
'addAtLeast': {t: (args?: [...ArgTypes[]]) => string;};
};
'sellers': {'seller': {t: (args?: [...ArgTypes[]]) => string;};
'edit': {t: (args?: [...ArgTypes[]]) => string;};
'select': {t: (args?: [...ArgTypes[]]) => string;};
'mp': {t: (args?: [...ArgTypes[]]) => string;};
'emMp': {t: ({store}: TemplateArgs) => string;};
'fBy': {t: (args?: [...ArgTypes[]]) => string;};
};
'StoreLocator': {'title': {t: (args?: [...ArgTypes[]]) => string;};
'home': {t: (args?: [...ArgTypes[]]) => string;};
'searchStore': {t: (args?: [...ArgTypes[]]) => string;};
'findNearestStore': {t: (args?: [...ArgTypes[]]) => string;};
'selected': {t: (args?: [...ArgTypes[]]) => string;};
'setAsMyStore': {t: (args?: [...ArgTypes[]]) => string;};
'hide': {t: (args?: [...ArgTypes[]]) => string;};
'expand': {t: (args?: [...ArgTypes[]]) => string;};
'clearSearch': {t: (args?: [...ArgTypes[]]) => string;};
'searchResults': {t: ({n, p}: TemplateArgs) => string;};
'searchResultsNearYou': {t: ({n}: TemplateArgs) => string;};
'getDirections': {t: (args?: [...ArgTypes[]]) => string;};
'stopDirections': {t: (args?: [...ArgTypes[]]) => string;};
'searchAPickUpStore': {t: (args?: [...ArgTypes[]]) => string;};
'noResults': {t: ({p}: TemplateArgs) => string;};
'noResultsNearYou': {t: (args?: [...ArgTypes[]]) => string;};
'locateStore': {t: (args?: [...ArgTypes[]]) => string;};
'directionNotAvailable': {t: (args?: [...ArgTypes[]]) => string;};
'distanceKM': {t: ({distance}: TemplateArgs) => string;};
};
'ProgressIndicator': {'label': {t: (args?: [...ArgTypes[]]) => string;};
};
'Files': {'Download': {t: (args?: [...ArgTypes[]]) => string;};
};
'PasswordInput': {'toggleLabel': {t: (args?: [...ArgTypes[]]) => string;};
};
'Common': {'decrement': {t: (args?: [...ArgTypes[]]) => string;};
'increment': {t: (args?: [...ArgTypes[]]) => string;};
'quantity': {t: ({n}: TemplateArgs) => string;};
'navHomeWithMessage': {t: ({message}: TemplateArgs) => string;};
'sizeChartTitle': {t: (args?: [...ArgTypes[]]) => string;};
};
'Close': {t: (args?: [...ArgTypes[]]) => string;};
'ErrorMessages': {'EmailEmpty': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidEmailFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
'ProductNotFound': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
};

}

export const requestTranslation = async ({
    locale,
    section,
}: {
    locale: Languages | string;
    section: Sections | string;
}) => {
    if (!manifest[locale] || typeof manifest[locale][section] !== 'function') {
        return {};
    }
    const loaded = await manifest[locale][section]();
    return JSON.parse(JSON.stringify(loaded.default)) as Translation;
};
